/* globals API_ROOT */


import { navigate } from "gatsby"
import { Email } from "../utils/models"

export function logout() {
  typeof window !== 'undefined'&&  localStorage.removeItem("jwt")
  typeof window !== `undefined` && navigate("/login")
}

export interface JWTToken {
  email: string
  id: string
  nbf: number
}

export interface qualityCount {
  qid: number
  Count: number
}

export interface skillRatings {
  sid: number
  rating: number
  count: number
}

export interface PublicProfile  {
  id        :     string
  created   :  Date
  name      :      string
  desc      :  string
  image     :   string
  skills    :  skillRatings[]
  qualities : qualityCount[]
}

export async function publicProfile(uid?: string): Promise<PublicProfile> {
  const response = await fetch(`${process.env.GATSBY_API_URL}/public/profile/` + uid, {
    headers: {
      "Content-Type": "application/json"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })

  return response.json()
}

// Example POST method implementation:
export async function postData(url = "", email = "") {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'omit', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({ email: email }) // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

function parseJwt(token: string) {
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(""))

  const jwt: JWTToken = JSON.parse(jsonPayload)
  return jwt.id
}

export function GetUID() {
  const jwt = typeof window !== 'undefined' && localStorage.getItem("jwt")
  if (jwt) {
    return parseJwt(jwt)
  }
}

export function testEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function selfEmail(email: string, selfEmails: Email[]) {
  for (var userEmail of selfEmails) {
    if (email.includes(userEmail.value)) {
      return true
    }
  }
  return false
}

export function trimString(value: string) {
  return value.trim()
}

export function generalEmail(email: string) {
  var splitted = email.split("@")
  if (splitted.length < 1) {
    return true
  }
  splitted = splitted[1].split(".")
  return splitted[0] == "gmail" ||
    splitted[0] == "yahoo" ||
    splitted[0] == "hotmail" ||
    splitted[0] == "aol" ||
    splitted[0] == "msn" ||
    splitted[0] == "wanadoo" ||
    splitted[0] == "orange" ||
    splitted[0] == "comcast" ||
    splitted[0] == "live" ||
    splitted[0] == "rediffmail" ||
    splitted[0] == "free" ||
    splitted[0] == "gmx" ||
    splitted[0] == "web" ||
    splitted[0] == "yandex" ||
    splitted[0] == "ymail" ||
    splitted[0] == "libero" ||
    splitted[0] == "outlook" ||
    splitted[0] == "uol" ||
    splitted[0] == "bol" ||
    splitted[0] == "mail" ||
    splitted[0] == "cox" ||
    splitted[0] == "sbcglobal" ||
    splitted[0] == "sfr" ||
    splitted[0] == "verizon" ||
    splitted[0] == "bigpond" ||
    splitted[0] == "terra" ||
    splitted[0] == "neuf" ||
    splitted[0] == "rocketmail" ||
    splitted[0] == "att" ||
    splitted[0] == "alice" ||
    splitted[0] == "ig" ||
    splitted[0] == "rambler" ||
    splitted[0] == "charter" ||
    splitted[0] == "t-online"
}