export interface Skill {
  id: number
  name: string
  logo: string
  color: Colors
  description: string
  improvements: Improvement[]
  subskills: SubSkill[]
}

export enum Colors {
  color1 = "rgb(255,171,194)",
  color2 = "rgb(19,204,164)",
  color3 = "rgb(173,175,222)",
  color4 = "rgb(255,67,104)",
  color5 = "rgb(88,155,255)",
  color6 = "rgb(245,133,100)",
  color7 = "rgb(255,207,125)",
  hover1 = "rgba(255,0,0,0.65)",
  hover2 = "rgba(245,124,0,0.65)",
  hover3 = "rgba(255,193,0,0.65)",
  hover4 = "rgba(0,137,255,0.65)",
  hover5 = "rgba(0,170,13,0.65)",
}

export interface SubSkill {
  id: number
  name: string
  description: string
}

export interface Improvement {
  id: number
  name: string
}

export interface SkillFeedback {
  sid: number
  skipped: boolean
  subSkillRatings: subSkillRating[]
  msg: string
  improvements: number[]
}

export interface User {
  id: string
  name: string
  desc: string
  image: string
  emails: Email[]
  skillTemplate: SkillTemplate[]
  emailHistory: string[]
  emailSubscriptions: EmailSubscriptions[]
}

export interface SkillTemplate {
  sid: number
  subSkills: number[]
}

export enum FeedbackStatus {
  REQUESTED = "REQUESTED",
  GIVEN = "GIVEN",
  REJECTED = "REJECTED",
}

export interface Email {
  value: string
  type: EmailType
}

export enum EmailType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  PENDING = "PENDING",
  BLOCKED = "BLOCKED",
}

export enum EmailSubscriptions {
  NEW_FEEDBACK = "NEW_FEEDBACK",
  FEEDBACK_REQUEST = "FEEDBACK_REQUEST",
  THANKS = "THANKS",
  FEATURE_UPDATES = "FEATURE_UPDATES",
  REMINDERS = "REMINDERS",
  REPORT = "REPORT",
}

export interface UserInfo {
  id: string
  name: string
  email: string
  desc: string
  image: string
}

export interface Feedback {
  id: string
  from: UserInfo
  to: UserInfo
  created: Date
  updated: Date
  status: FeedbackStatus
  request: FeedbackRequest
  qualities: number[]
  msg: string
  skillFeedbacks: SkillFeedback[]
}

export interface FeedbackRequest {
  msg: string
  skills: SkillMapping[]
}

export interface SkillMapping {
  sid: number
  subSkills: number[]
}

export interface NewProfileInput {
  name?: string
  desc?: string
  skillTemplate?: SkillTemplate[]
  emailHistory?: string[]
  emails?: Email[]
  emailSubscriptions?: EmailSubscriptions[]
}

export interface FeedbackInput {
  id: string
  skillFeedbacks: SkillFeedbackInput[]
  qualities: number[]
  msg: string
}

export interface SkillFeedbackInput {
  sid: number
  skipped: boolean
  msg: string
  improvements: number[]
  subSkillRatings: subSkillRating[]
}

export interface subSkillRating {
  sid: number
  rating: number
}

export interface SkillsPageObject {
  feedbacks: Feedback[]
  topImprovementz: Map<number, number>
  subskillsAvgRating: Map<number, number>
  skillAvgRating: number
}

export interface SkillData {
  name: string
  logo: string
  iconURL: string
  color: Colors
  description: string
  improvements: Record<number, string>
  subskills: Record<number, SubSkill>
}